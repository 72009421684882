@import "../../../assets/scss/mixing.scss";

// common styles for overlay and fullscreen
.g-multiple-choice-interaction-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .g-multiple-choice-interaction-question {
        width: 100%;
        max-width: 400px;
        border-radius: 2.75px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 0px 0px 8.25px rgba(0, 0, 0, 0.25);
    }

    .g-multiple-choice-interaction-input-container {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 400px;

        .g-multiple-choice-interaction-choices-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .g-multiple-choice-interaction-choice {
                cursor: pointer;
                width: auto;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 2.75px;
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                display: flex;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                text-shadow: 0px 0px 8.25px rgba(0, 0, 0, 0.25);
                transition: 0.3s;
                border: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.6);
                }

                &.g-multiple-choice-interaction-choice-active {
                    background: rgba(0, 0, 0, 0.8);
                }
            }
        }

        .g-multiple-choice-interaction-submit-button-container {
            .g-multiple-choice-interaction-submit-button {
                cursor: pointer;
                position: absolute;
                bottom: 0;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 2.75px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.8);
                }

                &:disabled {
                    cursor: not-allowed;
                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    &.g-multiple-choice-interaction-component-fullscreen {
        margin: 0 15px 25px 15px;

        .g-multiple-choice-interaction-question {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 22px;

            .g-multiple-choice-interaction-question-text {
                text-align: left;
                padding: 8px 10px;
            }
        }

        .g-multiple-choice-interaction-input-container {
            .g-multiple-choice-interaction-choices-container {
                gap: 5px;

                .g-multiple-choice-interaction-choice {
                    padding: 0 5px;
                    height: 30px;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            .g-multiple-choice-interaction-submit-button-container {
                width: 38px;

                .g-multiple-choice-interaction-submit-button {
                    height: 30px;
                    width: 38px;
                }
            }
        }
    }

    &.g-multiple-choice-interaction-component-overlay {
        margin: 0 6px 6px 6px;

        @include gander-media-breakpoint-up-sm {
            margin: 0 12px 12px 12px;
        }

        .g-multiple-choice-interaction-question {
            margin-bottom: 6px;
            font-size: 10px;
            line-height: 150%;

            @include gander-media-breakpoint-up-sm {
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 150%;
            }

            .g-multiple-choice-interaction-question-text {
                text-align: left;
                padding: 4px 6px;

                @include gander-media-breakpoint-up-sm {
                    padding: 6px 8px;
                }
            }
        }

        .g-multiple-choice-interaction-input-container {
            .g-multiple-choice-interaction-choices-container {
                gap: 2px;

                @include gander-media-breakpoint-up-sm {
                    gap: 4px;
                }

                .g-multiple-choice-interaction-choice {
                    padding: 0 3px;
                    height: 18px;
                    font-size: 10px;
                    line-height: 150%;

                    @include gander-media-breakpoint-up-sm {
                        padding: 0 4px;
                        height: 24px;
                        font-size: 12px;
                        line-height: 150%;
                    }
                }
            }

            .g-multiple-choice-interaction-submit-button-container {
                width: 18px;

                @include gander-media-breakpoint-up-sm {
                    width: 24px;
                }

                .g-multiple-choice-interaction-submit-button {
                    padding: 0 4px;
                    height: 18px;
                    width: 18px;

                    @include gander-media-breakpoint-up-sm {
                        padding: 0 6px;
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
    }
}