@import '../../assets/scss/mixing.scss';

.gander-full-screen-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // added large number here to avoid any other element blocking
    // this is not random number, this is max number found on browser
    z-index: 2147483647;

.gander-mask {
    width: 100%;
    height: 100%;
    display: block !important;
    z-index: 9999;
    background-color: #161616;
    position: fixed;
}

.gander-content {
    z-index: 10000;
    position: fixed;
    color: #fff;
    width: 100%;
    height: 100%;

    .gander-close-btn-with-overlay-container .g-close-btn-with-overlay {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 1;
        cursor: pointer;
    }


.gander-overlay-content-container {
    height: 100%;
    display: flex;

    .gander-video-carousel-container {
        width: 100%;
        overflow: hidden;
    }
}
}
}
