@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Open+Sans:wght@400;700&display=swap");
@import '../../assets/scss/mixing.scss';
$containerMargin: 0 6px 11px 6px;

.gander-widget-container {
    height: auto;
    max-width: 100%;
    font-family: "DM Sans";
    margin: 10px 0px 10px 0px;

    .gander-watch-review-container {
        display: flex;
        line-height: 100%;
        margin: $containerMargin;

        .gander-watch-review-content-left {
            display: flex;
            align-items: center;
        }

        .gander-watch-review-content-right {
            align-items: center;
            display: flex;

            div {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .gander-preview-container {
        display: flex;
        align-items: center;

        .g-scroll-icon {
            transition: all 0.09s ease;
            display: flex;
            height: 72px;
            align-items: center;
            z-index: 1;

            &:hover {
                opacity: 0.7;
            }

            &.g-hide-prev {
                width: 0px;
                opacity: 0;
                pointer-events: none;
            }

            svg.prev {
                transform: rotate(180deg);
            }
        }

        .gander-preview-item {
            scroll-behavior: smooth;
            height: 100%;
            width: 100%;
            margin: $containerMargin;
            // NOTE: all mobile device will show scroll
            overflow: scroll;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            scrollbar-width: none;
            margin: 0 6px;

            &::-webkit-scrollbar {
                display: none;
            }

            video::-webkit-media-controls  {
                display: none !important;
            }

            .g-close-button {
                cursor: pointer;
                z-index: 1000;
                position: absolute;
                top: 11px;
                right: 6px;
            }

            .gander-preview-item-wrapper {
                min-height: 70px;
                height: 100%;
                overflow: hidden;
                border-radius: inherit;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                width: 100%;
                position: relative;

                &:hover {
                    cursor: pointer;
                }

                .gander-preview-item-video-gif-container {
                    background-color: #000;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: inherit;
                    height: inherit;
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                    z-index: 0;

                    .gander-preview-item-video-gif {
                        border-radius: inherit;
                        object-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        z-index: 0;
                        width: 50%;
                        max-width: 100%; // fix for firefox landscape preview-videos
                    }
                }

                .gander-preview-item-video {
                    border-radius: inherit;
                    object-fit: cover;
                    object-position: center;
                    height: inherit;
                    min-height: inherit;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    max-width: 100%; // fix for firefox landscape preview-videos
                    z-index: 1;
                    margin: 0;
                }

                .gander-preview-item-display-name {
                    font-family: "DM Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 8px;
                    margin-top: 6px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 59px;
                    text-align: center;
                }

                &:last-child {
                    margin: 0;
                }

                .gander-react-video-player {
                    video {
                        object-fit: cover;
                        object-position: inherit;
                    }
                }

                &.gander-preview-item-wrapper-inframe {
                    // inframe mode specific styles

                    .gander-react-player-wrapper {
                        border-radius: inherit;

                        .gander-react-video-player {
                            z-index: 1;
                            position: relative;
                            border-radius: inherit;

                            video {
                                border-radius: inherit;
                            }
                        }
                    }

                    .g-pauseBtn {
                        opacity: 1;
                    }

                    .gander-inframe-top-right-controls {
                        position: absolute;
                        top: 1.7%;
                        right: 1.8%;
                        height: 10%;
                        min-height: 40px;
                        max-height: 56px;
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 4%;
                        z-index: 3;

                        svg {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .gander-remove-width {
                        svg {
                            width: unset;
                        }
                    }

                    .gander-react-video-player-controls-wrapper,
                    .g-pauseBtn,
                    .gander-fullscreen-icon {
                        cursor: none;
                        opacity: 0;
                        z-index: 2;
                    }

                    .g-maximize-btn-with-overlay,
                    .g-play-btn-with-overlay,
                    .g-pause-btn-with-overlay,
                    .g-mute-unmute-btn-with-overlay {
                        &:hover {
                            .gander-button-overlay {
                                fill-opacity: 0.4;
                            }
                        }
                    }

                    @include gander-media-breakpoint-up-sm {
                        &:hover {
                            .gander-react-video-player-controls-wrapper,
                            .g-playBtn,
                            .g-pauseBtn,
                            .gander-fullscreen-icon {
                                z-index: 2;
                                cursor: pointer;
                                opacity: 1;
                            }
                        }
                    }

                }

                &.gander-preview-item-wrapper-preview {
                    // preview mode specific styles
                }
            }
        }
    }
}