@mixin gander-media-breakpoint-up-5120 {
    @media screen and (min-width: 5120px) {
        @content;
    }
}
@mixin gander-media-breakpoint-up-3840 {
    @media screen and (min-width: 3840px) {
        @content;
    }
}

@mixin gander-media-breakpoint-up-2560 {
    @media screen and (min-width: 2559px) {
        @content;
    }
}

// Large devices (desktops, 1450px and up)
@mixin gander-media-breakpoint-up-1450 {
  @media screen and (min-width: 1450px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin gander-media-breakpoint-up-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin gander-media-breakpoint-up-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin gander-media-breakpoint-up-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}
// These standard breakpoints followed as per bootstrap5
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// );


@mixin custom-scrollbar {
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}