@import "../../assets/scss/mixing.scss";

.g-products-container {
  position: absolute;
  top: 2%;
  left: 2%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
  width: 15%;
  max-width: 52px;
  min-width: 40px;
  
  &.gander-is-landscape {
    left: 1%;
  }

  &.g-is-mobile {
    top: 2.5%;
  }

  &.g-products-container-fullscreen {
    top: 2.2%;
    left: 2.4%;

    &.gander-is-landscape {
      left: 1%;
    }

    &.g-is-mobile {
      top: 2%;
      width: 12%;
    }
  }

  button {
    all: unset;

    &:hover {
      cursor: pointer;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-height: 52px;
      min-height: 40px;
      aspect-ratio: 1 / 1;
      background: white;
      border-radius: 4px;
  
      @include gander-media-breakpoint-up-sm {
        border-radius: 8px;
      }
    }
  }
}