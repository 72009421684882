.gander-open-mode-center-controls {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .g-play-btn-with-overlay,
  .g-pause-btn-with-overlay {
    height: 10%;
    max-height: 56px;
    min-height: 40px;
  
    svg {
      height: 100%;
    }
  }
}