@import '../../assets/scss/mixing.scss';

.gander-container {
    z-index: 10000;
    position: fixed;
    // setting normal to avoid overriding from merchant's site
    line-height: normal;

    // to avoid showing override controls on chrome
    video::-webkit-media-controls {
        display: none;
    }

    .gander-player-mask {
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0) 32.81%, rgba(21, 21, 21, 0.5) 100%);
        z-index: 1;
        position: absolute;
        pointer-events: none;
        border-radius: inherit;
    }

    &.gander-right-bottom {
        bottom: 10px;
        right: 10px;
    }

    &.gander-right-top {
        top: 10px;
        right: 10px;
    }

    &.gander-right-center {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &.gander-left-bottom {
        bottom: 10px;
        left: 10px;
    }

    &.gander-left-top {
        top: 10px;
        left: 10px;
    }

    &.gander-left-center {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &.gander-centr-top {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.gander-centr-bottom {
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.gander-centr {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .g-close-btn {
        position: absolute;
        right: 5px;
        top: 9px;

        @include gander-media-breakpoint-up-sm {
            top: 12px;
        }

        svg {
            height: 18px;
            width: 22px;
        }

        @include gander-media-breakpoint-up-sm {
            right: 10px;
            top: 9px;

            svg {
                height: 24px;
            }
        }

    }

    .gander-switch-fullscreen-icon button {
        min-width: unset;
        box-shadow: unset;
    }

    .g-arrow-btn-with-overlay,
    .g-play-btn-with-overlay {
        opacity: 1;
        z-index: 1;
        transition: unset;

        &:hover {
            background: unset;
        }
    }

    .g-pause-btn-with-overlay {
        opacity: 0;
        z-index: 1;
        transition: unset;

        &.gander-is-mobile {
            opacity: 1;
        }

        &:hover {
            background: unset;
        }
    }

    .g-close-btn-with-overlay,
    .g-maximize-btn-with-overlay,
    .g-mute-unmute-btn-with-overlay {
        opacity: 1;
        z-index: 1;
        transition: unset;

        @include gander-media-breakpoint-up-sm {
            opacity: 0;
        }
    }

    .g-close-btn-with-overlay,
    .g-maximize-btn-with-overlay,
    .g-arrow-btn-with-overlay,
    .g-play-btn-with-overlay,
    .g-pause-btn-with-overlay,
    .g-mute-unmute-btn-with-overlay {
        &:hover {
            .gander-button-overlay {
                fill-opacity: 0.4;
            }
        }
    }

    .gander-react-video-player-controls-wrapper,
    .g-close-btn,
    .gander-fullscreen-icon {
        height: unset;
        line-height: unset;
        opacity: 1;
        z-index: 1;
        transition: unset;

        &:hover {
            background: unset;
        }

        @include gander-media-breakpoint-up-sm {
            opacity: 0;
        }
    }

    .gander-fullscreen-icon {
        bottom: 26px;
    }

    .g-pauseBtn {
        opacity: 0.0000001;
    }

    @include gander-media-breakpoint-up-sm {
        &:hover {
            .gander-react-video-player-controls-wrapper,
            .g-close-btn,
            .g-close-btn-with-overlay,
            .g-maximize-btn-with-overlay,
            .g-mute-unmute-btn-with-overlay,
            .g-playBtn,
            .g-pauseBtn,
            .gander-fullscreen-icon,
            .g-arrow-btn-with-overlay,
            .g-play-btn-with-overlay,
            .g-pause-btn-with-overlay {
                z-index: 1;
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}