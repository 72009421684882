@import "../../../assets/scss/mixing.scss";

// common styles for overlay and fullscreen
.g-short-answer-interaction-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .g-short-answer-interaction-question {
        border-radius: 2.75px;
        font-family: 'DM Sans';
        font-style: normal;
        color: #FFFFFF;
        text-shadow: 0px 0px 8.25px rgba(0, 0, 0, 0.25);
    }

    .g-short-answer-interaction-input-form {
        display: flex;

        .g-short-answer-interaction-input-box {
            border: 1px solid #EBEBEB;
            border-radius: 8px;
            color: #FFFFFF;
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            background: rgba(0, 0, 0, 0.3);

            &::placeholder {
                color: #FFFFFF;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: #FFFFFF;
            }

            &::-ms-input-placeholder {
                color: #FFFFFF;
            }
        }

        .g-short-answer-interaction-submit-button {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 2.75px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.8);
            }

            &:disabled {
                cursor: not-allowed;
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .g-short-answer-interaction-skip-button-container {
        text-align: left;

        .g-short-answer-interaction-skip-button {
            cursor: pointer;
            font-family: 'DM Sans';
            font-style: normal;
            text-decoration-line: underline;
            color: #FFFFFF;
            text-shadow: 0px 0px 8.25px rgba(0, 0, 0, 0.25);
            max-width: 80px;
        }
    }

    // styles for fullscreen with responsive styles
    &.g-short-answer-interaction-component-fullscreen {
        margin-left: 15px;
        margin-right: 15px;

        .g-short-answer-interaction-question {
            margin-bottom: 10px;
            width: 100%;
            max-width: 400px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;

            .g-short-answer-interaction-question-text {
                text-align: left;
                padding: 8px 10px;
            }
        }

        .g-short-answer-interaction-input-form {
            gap: 20px;
            width: 100%;
            max-width: 400px;

            .g-short-answer-interaction-input-box {
                width: 100%;
                height: 38px;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                padding: 7px 16px;
            }

            .g-short-answer-interaction-submit-button {
                height: 38px;
                width: 38px;
            }
        }

        .g-short-answer-interaction-skip-button-container {
            width: 100%;
            max-width: 400px;

            .g-short-answer-interaction-skip-button {
                font-weight: 500;
                font-size: 8px;
                line-height: 300%;
                margin-bottom: 12px;
            }
        }
    }

    // styles for overlay with responsive styles
    &.g-short-answer-interaction-component-overlay {
        margin-left: 6px;
        margin-right: 6px;

        @include gander-media-breakpoint-up-sm {
            margin-left: 12px;
            margin-right: 12px;
        }

        .g-short-answer-interaction-question {
            margin-bottom: 6px;
            font-size: 10px;
            line-height: 14px;
            width: 100%;
            max-width: 400px;
            font-weight: 500;

            @include gander-media-breakpoint-up-sm {
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 16px;
            }

            .g-short-answer-interaction-question-text {
                text-align: left;
                padding: 4px 6px;

                @include gander-media-breakpoint-up-sm {
                    padding: 6px 8px;
                }
            }
        }

        .g-short-answer-interaction-input-form {
            gap: 8px;
            width: 100%;
            max-width: 400px;

            @include gander-media-breakpoint-up-sm {
                gap: 10px;
            }

            .g-short-answer-interaction-input-box {
                height: 20px;
                font-size: 9px;
                line-height: 150%;
                padding: 4px 8px;
                width: 100%;
                font-weight: 500;
                border-radius: 4px;

                @include gander-media-breakpoint-up-sm {
                    height: 28px;
                    font-size: 12px;
                    line-height: 150%;
                    padding: 4px 8px;
                    border-radius: 6px;
                }
            }

            .g-short-answer-interaction-submit-button {
                height: 20px;
                width: 20px;

                @include gander-media-breakpoint-up-sm {
                    height: 28px;
                    width: 28px;
                }
            }
        }

        .g-short-answer-interaction-skip-button-container {
            width: 100%;
            max-width: 400px;

            .g-short-answer-interaction-skip-button {
                font-size: 6px;
                line-height: 300%;
                margin-bottom: 6px;
                font-weight: 500;

                @include gander-media-breakpoint-up-sm {
                    font-size: 8px;
                    line-height: 300%;
                    margin-bottom: 8px;
                }
            }
        }
    }
}