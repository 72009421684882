@import "./slick.scss";
@import "./slick-theme.scss";
@import '../mixing.scss';
.gander-video-carousel {
.slick-slider {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;

            .slick-slide > div {
                height: 100%;
            }

            .slick-slide > div > div {
                // NOTE:this is to avoid inline block row:0
                display: block !important;
                @include gander-media-breakpoint-up-sm {
                    height: 100%;
                }
            }
        }
    }
}

.slick-prev,
.slick-next {
    bottom: 60px;
    top: auto;
    z-index: 1;

    @include gander-media-breakpoint-up-sm {
        bottom: 30px;;
    }

    &::before {
        content: none;
    }
}

.slick-prev {
    left: calc(50% - 190px);
    bottom: 30px;
    @include gander-media-breakpoint-up-sm {
        left: calc(50% - 235px);
        bottom: 35px;
    }

    &.gander-non-shopify-prev {
        left: calc(50% - 175px);

        @include gander-media-breakpoint-up-sm {
            left: calc(50% - 215px);
        }
    }
}

.slick-next {
    right: calc(50% - 190px);
    transform: translateY(-50%) rotate(180deg);
    bottom: 20px;
    @include gander-media-breakpoint-up-sm {
        right: calc(50% - 235px);
        bottom: 24px;
    }

    &.gander-non-shopify-next {
        right: calc(50% - 175px);
        @include gander-media-breakpoint-up-sm {
            right: calc(50% - 215px);
        }
    }
}

.slick-disabled {
    display: none;

    path {
        fill: #C6C6C6;
    }
}
}