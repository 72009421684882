@import '../../../assets/scss/mixing.scss';

.g-mini-product-details-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 10;

    .g-add-to-cart-mask {
        width: 100%;
        position: absolute;
        border-radius: inherit;
        background: #00000040;
    }

    .g-interaction-product-details {
        position: absolute;
        background: white;
        width: 100%;
        bottom: 0;
        left: 0px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        max-height: 375px;
        min-height: 94px;
        z-index: -1;
        overflow: hidden;
        // transform: translateY(100%);

        @include gander-media-breakpoint-up-sm {
            min-height: 140px;
        }

        &.active {
            transition: all 0 ease-in-out;
            opacity: 1;
            z-index: 0;
            // transform: translateY(0%);
        }

        .g-interaction-product-details-close {
            .g-close-btn {
                position: absolute;
                right: 4px;
                top: 4px;
                opacity: 1;

                @include gander-media-breakpoint-up-sm {
                    right: 10px;
                    top: 10px;
                }

                svg {
                    fill: #000;
                    stroke: #000;
                    height: 18px;
                    width: 18px;
                    display: block;

                    @include gander-media-breakpoint-up-sm {
                        height: 20px;
                        width: 20px;
                    }

                    path {
                        fill: #000;
                        stroke: #000;
                    }
                }
            }

            img {
                height: 10px;
                width: 10px;
            }
        }

        .g-interaction-product-details-price-img {
            padding: 7px;
            display: flex;
            align-items: center;

            @include gander-media-breakpoint-up-sm {
                padding: 14px;
            }

            img {
                height: 40px;
                width: 40px;
                border-radius: 4px;
                object-fit: cover;
                object-position: top;

                @include gander-media-breakpoint-up-sm {
                    height: 60px;
                    width: 60px;
                }
            }

            .g-interaction-product-price-div {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 5px;

                @include gander-media-breakpoint-up-sm {
                    margin-left: 10px;
                }

                .g-interaction-product-details-title-span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 99px;
                    margin-top: 0px;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    color: #000000;

                    @include gander-media-breakpoint-up-sm {
                        width: 150px;
                        font-size: 14px;
                        margin-top: 6px;
                    }
                }

                .g-interaction-product-details-price-span {
                    white-space: nowrap;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90%;
                    font-weight: 700;
                    color: #737373;
                    margin-top: 0px;
                    font-size: 12px;

                    @include gander-media-breakpoint-up-sm {
                        font-size: 14px;
                        margin-top: 6px;
                    }
                }

                .g-interaction-product-details-compare-price-span {
                    font-weight: 400;
                    color: #737373;
                    margin-left: 10px;
                    text-decoration: line-through
                }
            }
        }

        .g-product-details-variant-dropdowns {
            padding: 0px 7px;
            overflow: auto;
            max-height: 110px;
            @include custom-scrollbar;

            select {
                width: 100%;
                height: 28px;
                margin-bottom: 6px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0px 10px;

                background: #FFFFFF;
                border: 1px solid rgba(151, 151, 151, 0.24);
                border-radius: 8px;

                -moz-appearance: none;
                /* Firefox */
                -webkit-appearance: none;
                /* Safari and Chrome */
                appearance: none;
                background: url(https://d1m0ae25uhy92x.cloudfront.net/gwr_assets/down_arrow.png);
                background-repeat: no-repeat;
                background-size: 12px 12px;
                -webkit-appearance: none;
                background-position: right;
                background-position-x: 94%;

                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 150%;
                color: #000929;
                outline: none !important;
                box-shadow: none !important;
                text-transform: capitalize;

                &:focus-visible {
                    outline: none !important;
                    box-shadow: none !important;
                }

                @include gander-media-breakpoint-up-sm {
                    font-size: 14px;
                    background-size: 16px 16px;
                    height: 38px;
                    padding: 0px 16px;
                }
            }
        }
    }

    // Fullscreen Responsive For Mobile
    &.g-product-details-fullscreen-mode {
        .g-interaction-product-details {
            min-height: 170px;
            border-radius: unset;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            .g-interaction-product-details-close {
                .g-close-btn {
                    right: 10px;
                    top: 10px;

                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }
            }

            .g-interaction-product-details-price-img {
                padding: 20px;
                width: calc(100% - 40px);

                img {
                    height: 80px;
                    width: 80px;
                    flex: 0 0 80px;
                }

                .g-interaction-product-price-div {
                    margin-left: 14px;
                    width: 100%;

                    .g-interaction-product-details-title-span {
                        width: calc(100% - 50px);
                        font-size: 20px;
                        line-height: 27px;
                    }

                    .g-interaction-product-details-price-span {
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 6px;
                    }

                    .g-interaction-product-details-compare-price-span {}
                }
            }

            .g-product-details-variant-dropdowns {
                padding: 0px 10px;

                select {
                    padding: 0px 16px;
                    font-size: 16px;
                    height: 48px;
                }
            }
        }
    }
}