.gander-open-mode-footer {
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: inherit;
  
  &.gander-no-branding {
    margin-bottom: 2%;
  }

  .g-gander-logo-v2 {
      align-self: center;
  }
}

.gander-footer-with-overlay {
  background: rgba(0, 0, 0, 0.3);
}