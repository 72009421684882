@import '../../../assets/scss/mixing.scss';

.gander-video-carousel {
    max-height: 100%;
    height: 100%;
    display: block;

    .g-close-btn-with-overlay,
    .g-play-btn-with-overlay,
    .g-pause-btn-with-overlay,
    .g-arrow-btn-with-overlay,
    .g-mute-unmute-btn-with-overlay {
        &:hover {
            .gander-button-overlay {
                fill-opacity: 0.4;
            }

            cursor: pointer;
        }
    }

    .g-arrow-btn-with-overlay {
        position: absolute;
        right: 2.6%;
        top: 43%;
        height: 8%;
        width: auto;
        z-index: 1;
        
        svg {
            transform: rotate(-90deg);
            height: 100%;
            aspect-ratio: 1 / 1;
        }
    }

    .g-arrow-btn-with-overlay ~ .g-arrow-btn-with-overlay {
        top: 51%;
        margin-top: 4px;

        svg {
            transform: rotate(90deg);
        }
    }

    .gander-react-player-wrapper {
        height: 100%;
    }

    @include gander-media-breakpoint-up-sm {
        padding: 68px 0;
    }

    @include gander-media-breakpoint-up-2560 {
        padding: 98px 0;
    }

    @include gander-media-breakpoint-up-3840 {
        padding: 118px 0;
    }

    @include gander-media-breakpoint-up-5120 {
        padding: 140px 0;
    }

    &.gander-single-child {
        .slick-track {
            display: flex;
            justify-content: center;
        }
    }

    .slick-slider {
        .slick-list {
            // Note: do not put margin directly to video container as it will cause width issue
            margin: 0 -15px !important;

            @include gander-media-breakpoint-up-sm {
                margin: 0 -21px !important;
            }

            .slick-track {
                .slick-slide {
                    padding: 0 15px;
                    max-height: 100vh; // adding to limit height

                    @include gander-media-breakpoint-up-sm {
                        padding: 0 21px;
                    }

                    // TODO: structure: put .gander-video-container inside this, once all MR from everyone is merged
                }
            }
        }
    }

    .gander-video-container {
        position: relative;
        height: 100%;
        width: auto;
        overflow: hidden;
        isolation: isolate; // for safari radius support

        &.gander-video-container-portrait {
            @include gander-media-breakpoint-up-sm {
                $width: calc(100vh * 0.47);
                width: $width;
                min-width: $width;
                max-width: $width;
            }

            &.gander-video-container-preview-mode {
                $width: calc(100vh * 0.67);
                width: $width;
                min-width: $width;
                max-width: $width;
            }
        }

        &.gander-video-container-landscape {
            @include gander-media-breakpoint-up-sm {
                $width: calc(100vh * 1.60);
                width: $width;
                min-width: $width;
                max-width: $width;
            }
        }

        .gander-player-mask {
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0) 32.81%, rgba(21, 21, 21, 0.5) 100%);
            z-index: 1;
            position: absolute;
            pointer-events: none;
        }

        .gander-video-loader-overlay {
            opacity: 1;
            height: 'auto';
            z-index: 0;
            background-color: black;
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: inherit;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                overflow: hidden;
                object-fit: cover;
            }

            video {
                width: 100px;
                height: 100px;

                @include gander-media-breakpoint-up-sm {
                    width: 160px;
                    height: 160px;
                }
            }

            &.gander-video-loader-landscape {
                max-height: 225px;
                top: 50%;
                min-height: 225px;
                transform: translateY(-50%);

                @include gander-media-breakpoint-up-sm {
                    top: unset;
                    max-height: unset;
                    transform: unset;
                }
            }
        }

        .gander-video-progress-bar-overlay {
            opacity: 1;
            width: auto;
            overflow: hidden;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: inherit;
            height: 5px;
        }

        .gander-react-video-player {
            position: relative;

            &.gander-video-player-landscape {
                height: 100%;

                video {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    max-height: 230px;
                    min-height: 230px;
                    transform: translateY(-50%);
                }

                @include gander-media-breakpoint-up-sm {
                    top: unset;
                    transform: unset;

                    video {
                        position: unset;
                        top: unset;
                        left: unset;
                        max-height: unset;
                        transform: unset;
                    }
                }
            }

            video {
                border-radius: unset;

                @include gander-media-breakpoint-up-sm {
                    border-radius: inherit;
                }

                object-fit: cover;
            }
        }

        .gander-fullscreen-icon {
            cursor: pointer;
            top: 20px;
            right: 20px;
            z-index: 1;
            bottom: unset;

            @include gander-media-breakpoint-up-3840 {
                top: 30px;
                right: 30px;
            }

            @include gander-media-breakpoint-up-5120 {
                top: 40px;
                right: 40px;
            }

            svg {
                @include gander-media-breakpoint-up-2560 {
                    height: 30px;
                    width: 30px;
                }

                @include gander-media-breakpoint-up-3840 {
                    height: 46px;
                    width: 46px;
                }

                @include gander-media-breakpoint-up-5120 {
                    height: 55px;
                    width: 55px;
                }
            }

            &.gander-full-screen-landscape {
                @include gander-media-breakpoint-up-sm {
                    top: 20px;
                    right: 20px;
                }

                @include gander-media-breakpoint-up-3840 {
                    top: 30px;
                    right: 30px;
                }
            }
        }

        @include gander-media-breakpoint-up-sm {
            &:hover {

                .g-playBtn,
                .g-pauseBtn {
                    z-index: 1;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }

        .gander-react-video-player-controls-wrapper {
            opacity: 1;
            transition: all 0.3s ease-in-out;
            position: absolute;
            top: 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
            z-index: 1;

            @include gander-media-breakpoint-up-sm {
                bottom: 124px;
                height: 35px;
                top: unset;
            }

            @include gander-media-breakpoint-up-2560 {
                bottom: 184px;
            }

            @include gander-media-breakpoint-up-3840 {
                bottom: 200px;
            }

            @include gander-media-breakpoint-up-5120 {
                bottom: 245px;
            }

            &.gander-video-controls-landscape {
                @include gander-media-breakpoint-up-sm {
                    bottom: 124px;
                }

                @include gander-media-breakpoint-up-2560 {
                    bottom: 174px;
                }

                @include gander-media-breakpoint-up-3840 {
                    bottom: 196px;
                }

                @include gander-media-breakpoint-up-5120 {
                    bottom: 240px;
                }

            }

            .gander-controls-container {
                .gander-switch-fullscreen-icon {
                    display: none;
                }

                .gander-fullscreen-icon {
                    display: none;
                }

                .gander-time-display {
                    font-size: 16px;
                    display: none;

                    @include gander-media-breakpoint-up-sm {
                        display: block;
                    }

                    @include gander-media-breakpoint-up-2560 {
                        font-size: 24px;
                        padding-bottom: 9px;
                    }

                    @include gander-media-breakpoint-up-3840 {
                        font-size: 35px;
                        padding-bottom: 16px;
                    }

                    @include gander-media-breakpoint-up-5120 {
                        font-size: 50px;
                        padding-bottom: 22px;
                    }
                }
            }

            .gander-time-controls {
                height: 5px;
                display: flex;
                justify-content: center;

                @include gander-media-breakpoint-up-sm {
                    height: 6px;
                }

                @include gander-media-breakpoint-up-2560 {
                    height: 10px;
                }

                @include gander-media-breakpoint-up-3840 {
                    height: 12px;
                }

                @include gander-media-breakpoint-up-5120 {
                    height: 18px;
                }

                .gander-progressbar-container {
                    height: inherit;
                    width: 100%;
                    position: relative;
                    overflow: hidden;

                    @include gander-media-breakpoint-up-sm {
                        width: calc(100% - 10px);
                        border-radius: 6px;
                        background: rgba(255, 255, 255, .5);
                    }

                    .gander-progressbar {
                        display: block;
                        width: 100%;
                        background: white;
                        height: inherit;

                        @include gander-media-breakpoint-up-sm {
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
}