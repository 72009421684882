// Note from nirav
// we should not use any global tags/higher level tags for ex. `body`, `html` etc
// as it will affect all websites
.gander-full-screen-container {

    *,
    *::before,
    *::after {
        // box-sizing: inherit; // todo :
        box-sizing: border-box !important;
        line-height: 20px;
    }
    // to avoid showing override controls on chrome
    video::-webkit-media-controls {
        display: none;
    }
}

// NOTE: This is adding to body tag when we open fullscreen mode
.gander-full-screen-on {
    overflow: hidden !important;
}