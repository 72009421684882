@import '../assets/scss/mixing.scss';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap');

.gander-global-embedded-container {
    $bg-skeleton : #D9D9D9;
    height: 100vh;
    padding-top: 10px;

    .gander-global-embedded-wrapper {

        .gander-global-embedded-left-container {
            height: 70vh;
            background-color: $bg-skeleton;
            margin: 15px;
        }

        .gander-global-embedded-right-container {

            .gander-global-embedded-title-skeleton {
                background-color: $bg-skeleton;
                margin: 15px;
                font-size: x-large;
                width: 75vw;
            }

            .gander-global-embedded-price-skeleton {
                background-color: $bg-skeleton;
                margin: 15px;
                font-size: medium;
                width: 40vw;
            }

            .gander-global-embedded-widget-skeleton {
                margin: 15px;
            }

            .gander-global-embedded-details-skeleton {
                background-color: $bg-skeleton;
                margin: 15px;
                display: none;
            }

        }

        .gander-global-embedded-extra-details-mobile-skeleton {

            .gander-global-embedded-extra-details-mobile-dots-title {
                background-color: $bg-skeleton;
                height: 18px;
                margin: 15px;
                width: 45vw;
            }

            .gander-global-embedded-extra-details-mobile-dots-container {
                display: flex;
                margin: 0 15px;

                .gander-global-embedded-extra-details-mobile-dots {
                    background-color: $bg-skeleton;
                    height: 29px;
                    width: 29px;
                    border-radius: 50px;
                    margin-right: 6px;
                }
            }
        }

        .gander-global-embedded-extra-details-skeleton {
            display: none;
            background-color: $bg-skeleton;
            margin: 15px;
        }
    }

    @include gander-media-breakpoint-up-sm {
        .gander-global-embedded-wrapper {
            display: flex;
            min-height: 80%;
            height: auto;
            overflow: hidden;

            .gander-global-embedded-left-container {
                height: auto;
                width: 35%;
                background-color: $bg-skeleton;
                margin: 33px;
            }

            .gander-global-embedded-right-container {
                width: 65%;
                margin: 33px 33px 33px 0;

                .gander-global-embedded-title-skeleton {
                    background-color: $bg-skeleton;
                    font-size: xx-large;
                    margin: unset;
                    width: 18vw;
                }

                .gander-global-embedded-price-skeleton {
                    background-color: $bg-skeleton;
                    font-size: xx-large;
                    margin: unset;
                    width: 12vw;
                    margin: 15px 0;
                }

                .gander-global-embedded-widget-skeleton {
                    margin: unset;
                    margin: 20px 0;
                }

                .gander-global-embedded-details-skeleton {
                    display: block;
                    background-color: $bg-skeleton;
                    margin: unset;
                    height: 40vh;
                }

            }

            .gander-global-embedded-extra-details-mobile-skeleton {
                display: none;
            }

            .gander-global-embedded-extra-details-skeleton {
                background-color: $bg-skeleton;
                margin: 20px;
            }
        }

        .gander-global-embedded-extra-details-container {
            background-color: $bg-skeleton;
            height: 20%;
            display: block;
            margin: 20px 33px 0px 33px;
        }
    }

}
// separate parent
.gander-global-preview-container-skeleton {
    height: calc(100vh + 10px);
}