.gander-fullscreen-mode-center-controls {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .g-play-btn-with-overlay,
  .g-pause-btn-with-overlay {
    opacity: 1;
    height: 10%;
    max-height: 56px;
    min-height: 40px;
  
    svg {
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .g-pause-btn-with-overlay {
    opacity: 0;
  }

  &:hover {
    .g-pause-btn-with-overlay {
      opacity: 1;
    }
  }
}