@import '../../assets/scss/mixing.scss';

.gander-preview-video-detail {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    width: 100%;

    .gander-preview-video-detail-wrapper {
        width: inherit;

        &.gander-preview-square-circle-or-bordered {
            display: flex;
            justify-content: center;
        }

        &.gander-preview-portrait-landscape {
            .gander-preview-video-detail-container {
                &.gander-preview-video-view-count {
                    float: left;
                }

                &.gander-preview-video-duration {
                    float: right;
                }
            }
        }

        .gander-preview-video-detail-text {
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
        }

        .gander-preview-video-detail-container {
            display: flex;
            align-items: center;

            svg {
                padding-bottom: 1px;
            }
        }
    }

    &.gander-embedded-video-duration {
        .gander-preview-video-detail-wrapper {
            font-size: 8px;

            svg {
                height: 8px;
                width: 8px;
            }

            @include gander-media-breakpoint-up-sm {
                font-size: 10px;

                svg {
                    height: 10px;
                    width: 10px;
                }
            }
        }
    }

    &.gander-preview-video-duration {
        .gander-preview-video-detail-wrapper {
            font-size: 8px;

            svg {
                height: 8px;
                width: 8px;
            }

            @include gander-media-breakpoint-up-sm {
                font-size: 14px;

                svg {
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }
}