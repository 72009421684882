@import '../../../assets/scss/mixing.scss';

.g-product-details-sidebar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 400px;
    z-index: 2147483648;
    background: #FFFFFF;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    opacity: 0;

    @include gander-media-breakpoint-up-1450 {
        width: 649px;
    }

    @include gander-media-breakpoint-up-3840 {
        width: 1000px;
    }

    &.g-product-details-sidebar-container-active {
        transform: translateX(0);
        opacity: 1;

        .g-product-details-sidebar-drawer-toggler {
            transform: rotate(0deg);
        }
    }

    .g-product-details-sidebar-drawer-toggler {
        position: absolute;
        top: calc(50% - 32.5px);
        right: -32.5px;
        transform: rotate(-180deg);
        transition: all 0.3s ease-in-out;
        z-index: 1;
        svg {
            border-radius: 50%;
            box-shadow: 0px 55.3704px 105.926px rgba(176, 183, 195, 0.14);
            height: 65px;
            width: 65px;
            display: block;
            background: white;
        }

    }

    .g-product-details-sidebar-content {
        overflow: auto;
        @include custom-scrollbar;
        display: block;
        height: 100%;
        padding: 20px 34px;

        @include gander-media-breakpoint-up-1450 {
            padding: 47px 74px;
        }

        .g-product-details-sidebar-image-container {

            .g-product-details-sidebar-main-div {
                width: 100%;
                height: 250px;

                @include gander-media-breakpoint-up-1450 {
                    width: 500px;
                    height: 500px;
                }

                @include gander-media-breakpoint-up-3840 {
                    width: 850px;
                    height: 850px;
                }

                img.g-product-details-sidebar-main-img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                }
            }

            .g-product-details-sidebar-preview-img-slider {
                @include custom-scrollbar;
                overflow: auto;
                overflow-y: hidden;
                display: flex;
                flex-direction: row;
                margin: 24px 0;

                @include gander-media-breakpoint-up-3840 {
                    margin: 24px 0;
                }

                .g-product-details-sidebar-preview-img-item {
                    cursor: pointer;
                    flex: 0 0 60px;
                    min-height: 60px;
                    height: 60px;
                    width: 60px;
                    border-radius: 8px;

                    @include gander-media-breakpoint-up-1450 {
                        flex: 0 0 80px;
                        min-height: 80px;
                        height: 80px;
                        width: 80px;
                    }

                    @include gander-media-breakpoint-up-3840 {
                        flex: 0 0 150px;
                        height: 150px;
                        min-height: 150px;
                        width: 150px;
                    }


                    .g-product-details-sidebar-preview-img {
                        height: 100%;
                        width: 100%;
                        padding: 0 5px;
                        object-fit: contain;
                    }

                }
            }
        }

        .g-product-details-sidebar-price-title-container {
            margin: 24px 0;
            display: flex;
            flex-direction: column;

            .g-product-details-sidebar-title-span {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #000000;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @include gander-media-breakpoint-up-1450 {
                    font-size: 32px;
                    line-height: 44px;
                }

                @include gander-media-breakpoint-up-3840 {
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            .g-product-details-sidebar-price-span {
                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #737373;

                @include gander-media-breakpoint-up-1450 {
                    font-size: 28px;
                    line-height: 35px;
                }

                @include gander-media-breakpoint-up-3840 {
                    font-size: 38px;
                    line-height: 45px;
                }

                .g-product-details-sidebar-compare-price-span {
                    margin-left: 10px;
                    text-decoration: line-through;
                    font-weight: 400;
                }
            }
        }

        .g-product-details-sidebar-form-container {
            display: block;

            .g-product-details-variant-dropdowns {
                select {
                    width: 100%;
                    height: 38px;
                    background: #FFFFFF;
                    border: 1px solid rgba(151, 151, 151, 0.24);
                    border-radius: 8px;
                    padding: 0px 16px;
                    margin-bottom: 10px;

                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                    color: #000929;

                    -moz-appearance: none;
                    /* Firefox */
                    -webkit-appearance: none;
                    /* Safari and Chrome */
                    appearance: none;
                    background: url(https://d1m0ae25uhy92x.cloudfront.net/gwr_assets/down_arrow.png);
                    background-repeat: no-repeat;
                    background-size: 16px 16px;
                    -webkit-appearance: none;
                    background-position: right;
                    background-position-x: 97%;

                    &:focus-visible {
                        outline: none !important;
                        box-shadow: none !important;
                    }

                    @include gander-media-breakpoint-up-1450 {
                        height: 48px;
                        padding: 12px 16px;
                        font-size: 16px;
                    }

                    @include gander-media-breakpoint-up-3840 {
                        height: 78px;
                        padding: 12px 16px;
                        font-size: 26px;
                    }

                }
            }

            .g-product-details-sidebar-add-to-cart-button-div {
                padding: 12px;
                width: 100%;
                height: 44px;
                border-radius: 4px;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                outline: none !important;
                box-shadow: none !important;

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }

    }
}