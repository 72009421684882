@import '../../assets/scss/mixing.scss';

.g-product-details-form-btn {
    width: calc(100% - 14px);
    max-width: 400px;
    height: 26px;
    margin: 4px auto;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    outline: none;
    box-shadow: unset;
    border: none;
    background: #3c8ef9;

    .g-product-details-btn-text {
        color: white;
        
        &:hover {
            cursor: pointer;
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    @include gander-media-breakpoint-up-sm {
        height: 36px;
        font-size: 14px;
        margin: 10px auto 4px auto;
    }

    &.g-product-details-form-btn-fullscreen-mode {
        height: 36px;
        font-size: 14px;
        margin: 10px auto 4px auto;

        @include gander-media-breakpoint-up-1450 {
            height: 44px;
            font-size: 16px;
        }

        @include gander-media-breakpoint-up-3840 {
            height: 78px;
            font-size: 26px;
        }
    }
}