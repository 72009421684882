@import '../../../assets/scss/mixing.scss';

.gander-video-loader-overlay {
    z-index: 0;
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
    }

    video {
        width: 100px;
        height: 100px;

        @include gander-media-breakpoint-up-sm {
            width: 160px;
            height: 160px;
        }
    }
}

.gander-video-player-container {
    max-height: 90vh;
    max-width: 90vw;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 20px 2px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 10px;

    .gander-open-mode-right-controls {
        position: absolute;
        right: 1.8%;
        top: 25%;
        height: 50%;
        width: 13%;
        z-index: 1;
        min-width: 40px;
        max-width: 56px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 5px;

        &.gander-is-landscape {
            right: 1%;
            width: 5.5%;
        }

        .g-arrow-btn-with-overlay {
            transform: rotate(90deg);
        }

        .g-arrow-btn-with-overlay ~ .g-arrow-btn-with-overlay {
            transform: rotate(-90deg);
        }

        svg {
            height: 100%;
            width: 100%;
        }
    }

    video {
        object-fit: cover;
        width: inherit;
        height: inherit;
        top: 0;
        overflow: hidden;
    }

    .gander-react-video-player-controls-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        &.with-shopify-shop-now {
            bottom: 25px;

            @include gander-media-breakpoint-up-sm {
                bottom: 56px;
            }
        }

        &.with-non-shopify-shop-now {
            bottom: 25px;

            @include gander-media-breakpoint-up-sm {
                bottom: 35px;
            }
        }


        // TODO: combine this css with carousel and override only which params are required to override
        .gander-time-controls {
            height: 60px;
            flex: 1;
            display: flex;
            justify-content: center;

            .gander-progressbar-container {
                height: 6px;
                width: calc(100% - 10px);
                background: rgba(255, 255, 255, .5);
                border-radius: 6px;
                position: relative;
                overflow: hidden;

                .gander-progressbar {
                    display: block;
                    width: 100%;
                    background: white;
                    border-radius: 6px;
                    height: 6px;
                }
            }
        }

    }
}