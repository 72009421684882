.g-close-mode-container {
    height: 70px;
    width: 70px;
    max-height: 70px;
    max-width: 70px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    box-sizing: border-box;

    .g-close-mode-content {
        height: 100%;
        width: 100%;

        img,
        video {
            height: 70px;
            width: 70px;
            object-fit: cover;
            object-position: center;
            border-radius: inherit;
            max-width: 100%;
            max-height: 100%;
            box-sizing: border-box;
        }

        .g-playBtn {
            z-index: 1000000000;
            position: absolute;
            top: 53%;
            left: 53%;
            opacity: 1;
            transform: translate(-50%, -50%);
            padding: 25px;

            svg {
                height: 15px;
                width: 15px;
            }

        }
    }
}