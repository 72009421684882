.gander-react-video-player-time-bar {
    border-radius: inherit;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .gander-time-bar-controls {
        height: 5px;
        display: flex;
        justify-content: center;
        border-radius: inherit;

        .gander-time-bar-progressbar-container {
            height: inherit;
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: inherit;
            cursor: pointer;

            .gander-time-bar-progressbar {
                display: block;
                width: 100%;
                background: white;
                height: inherit;
                transition: all 1.0s linear;
            }
        }
    }
}