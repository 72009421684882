@import "../../../assets/scss/mixing.scss";

.g-call-to-action-interaction-component {
    width: 100%;
    display: flex;
    justify-content: center;

    .g-call-to-action-interaction-button {
        cursor: pointer;
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        border: none;
        transition: 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    &.g-call-to-action-interaction-component-fullscreen {
        .g-call-to-action-interaction-button {
            margin: 0 12px 16px 12px;
            height: 44px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
        }
    }

    &.g-call-to-action-interaction-component-overlay {
        .g-call-to-action-interaction-button {
            margin: 0 6px 4px 6px;
            height: 26px;
            border-radius: 4px;
            font-size: 12px;
            line-height: 120%;
            font-weight: 500;

            @include gander-media-breakpoint-up-sm {
                margin: 0 10px 4px 10px;
                height: 36px;
                border-radius: 4px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
            }
        }
    }
}