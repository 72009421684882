@import '../../../../assets/scss/mixing.scss';

.gander-fullscreen-mode-top-right-controls {
  position: absolute;
  top: 1.7%;
  right: 2.6%;
  height: 9%;
  max-width: 110px;
  z-index: 1;
  min-height: 40px;
  max-height: 56px;
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;

  &.gander-is-landscape {
    right: 1%;
  }

  @include gander-media-breakpoint-up-sm {
    max-width: unset;
    height: 10%;
    gap: 4%;
  }

  svg {
      height: 100%;
      width: 100%;
      min-width: 40px;
      max-width: 56px;
  }
}