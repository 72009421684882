@import '../../../assets/scss/mixing.scss';

.gander-landscape {
    .g-preview-main-container {
        .g-preview-container {
            min-height: 92px;
            min-width: 163px;

            @include gander-media-breakpoint-up-sm {
                min-height: 152px;
                min-width: 207px;
            }
        }
    }
}

.gander-portrait {
    .g-preview-main-container {
        .g-preview-container {
            min-height: 163px;
            min-width: 92px;

            @include gander-media-breakpoint-up-sm {
                min-height: 207px;
                min-width: 152px;
            }
        }
    }
}

.g-preview-main-container {
    border-radius: inherit;

    .g-preview-container {
        cursor: pointer;
        border-radius: inherit;
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 20px 2px;
        overflow: hidden;

        .g-preview-player {
            border-radius: inherit;
        }

        video {
            object-fit: cover;
            width: inherit;
            height: inherit;
            position: relative;
            top: 0;
            overflow: hidden;
            z-index: -1;
            border: none;
            border-radius: inherit;
        }

        .gander-preview-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 10px;
            white-space: nowrap;
            display: flex;
            color: #fff;
            z-index: 1;
            align-items: center;
            max-width: 100%;

            .gander-preview-state-text {
                margin: 0 5px;
                font-weight: 500;
                overflow-wrap: break-word;
                white-space: initial;
            }
        }
    }
}