.gander-fullscreen-mode-footer {
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: inherit;

  &.gander-no-branding {
    margin-bottom: 10px;
  }

  .g-gander-logo-v2 {
      align-self: center;
  }
}