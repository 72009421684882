@import "../../../assets/scss/mixing.scss";

// common styles for overlay and fullscreen
.g-picture-choice-interaction-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .g-picture-choice-interaction-question {
        width: 100%;
        max-width: 400px;
        border-radius: 2.75px;
        text-shadow: 0px 0px 8.25px rgba(0, 0, 0, 0.25);

        .g-picture-choice-interaction-question-text {
            text-align: left;
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .g-picture-choice-interaction-input-container {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 400px;

        .g-picture-choice-interaction-choices-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;

            .g-picture-choice-interaction-choice {
                border-radius: 4px;
                border: none;
                padding: 0;

                .g-picture-choice-interaction-choice-img {
                    border-radius: inherit;
                    height: 100%;
                    width: 100%;
                    opacity: inherit;
                    object-fit: fill;
                }

                &:hover {
                    border: 1px solid white;
                    opacity: 0.8;
                }

                &.g-picture-choice-interaction-choice-active {
                    border: 1px solid white;
                    opacity: 0.8;
                }
            }
        }

        .g-picture-choice-interaction-submit-button-container {
            .g-picture-choice-interaction-submit-button {
                position: absolute;
                bottom: 0;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 2.75px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.8);
                }

                &:disabled {
                    cursor: not-allowed;
                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    // styles for overlay with responsive styles
    &.g-picture-choice-interaction-component-overlay {
        margin: 0 8px 8px 8px;

        @include gander-media-breakpoint-up-sm {
            margin: 0 10px 10px 10px;
        }

        .g-picture-choice-interaction-question {
            margin-bottom: 6px;
            font-size: 10px;
            line-height: 150%;

            @include gander-media-breakpoint-up-sm {
                margin-bottom: 8px;
                font-size: 12px;
            }

            .g-picture-choice-interaction-question-text {
                padding: 4px 6px;

                @include gander-media-breakpoint-up-sm {
                    padding: 6px 8px;
                }
            }
        }

        .g-picture-choice-interaction-input-container {
            .g-picture-choice-interaction-choices-container {
                gap: 8px;

                @include gander-media-breakpoint-up-sm {
                    gap: 14px;
                }

                .g-picture-choice-interaction-choice {
                    width: 26px;
                    height: 26px;

                    @include gander-media-breakpoint-up-sm {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            .g-picture-choice-interaction-submit-button-container {
                width: 26px;

                @include gander-media-breakpoint-up-sm {
                    width: 40px;
                }

                .g-picture-choice-interaction-submit-button {
                    height: 26px;
                    width: 26px;

                    @include gander-media-breakpoint-up-sm {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
    }

    // styles for fullscreen with responsive styles
    &.g-picture-choice-interaction-component-fullscreen {
        margin: 0 15px 15px 15px;

        .g-picture-choice-interaction-question {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 22px;

            .g-picture-choice-interaction-question-text {
                padding: 8px 10px;
            }
        }

        .g-picture-choice-interaction-input-container {
            .g-picture-choice-interaction-choices-container {
                gap: 20px;

                .g-picture-choice-interaction-choice {
                    width: 50px;
                    height: 50px;
                }
            }

            .g-picture-choice-interaction-submit-button-container {
                width: 38px;

                .g-picture-choice-interaction-submit-button {
                    height: 38px;
                    width: 38px;
                }
            }
        }
    }
}